// @flow
import * as React from "react";

export default function BrochuresCard(props) {
  return (
    <div className="col-md-6 col-lg-3">
      <div className="special-item">
        <figure className="special-img">
          <a href={props.pdf} target="_blank" rel="noreferrer">
            <img src={props.photoURL} alt={props.title} />
          </a>
        </figure>
        <div className="special-content">
          <div className="package-price">
            <h4>{props.title} </h4>
            {props.pdf && (
              <a href={props.pdf} className="button-primary">
                {" "}
                Download
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
